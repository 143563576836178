<template>
  <vue-table-card title="Course Register" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                  :extra-params="{user_type, payment_status}">
    <template #buttons>
      <div class="btn-group">
        <custom-drop-btn text="Filters" ref="dropDown" icon="fa fa-filter" size="sm" dropdown-width="40r">
          <i @click="$refs.dropDown.onClickedOutside()" class="fa fa-close pr-3 pt-3 fl-eqh-r"/>
          <div class="row p-3">
            <validated-vue-select name="User Type" :options="userTypeOptions"
                                  label="User Type" v-model="user_type" class="col-6"/>
            <validated-vue-select name="Payment Status" :options="statusOptions"
                                  label="Payment Status" v-model="payment_status" class="col-6"/>
          </div>
        </custom-drop-btn>
      </div>
    </template>
    <template #is_online_payment="{rowData}">
      <div v-if="rowData.is_online_payment">Online</div>
      <div v-else>Offline</div>
    </template>
    <template #actions="{rowData}">
      <div class="btn-group">
        <btn :disabled="updatingPayment" :loading="updatingPayment" loading-text="Processing..."
             v-if="!rowData.is_online_payment && rowData.payment_status!=='Transaction Completed'" size="sm"
             title="Receive Payment" @click="updatePaymentStatus(rowData.id)"/>
        <btn size="sm" title="Details" @click="viewDetails(rowData)"/>
      </div>
    </template>
  </vue-table-card>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'CourseRegisters',
    data () {
        return {
            updatingPayment: false,
            user_type: '',
            payment_status: '',
            statusOptions: [
                {
                    label: 'Payment Pending',
                    value: 'Payment Pending'
                },
                {
                    label: 'Transaction Processing',
                    value: 'Transaction Processing'
                },
                {
                    label: 'Transaction Completed',
                    value: 'Transaction Completed'
                },
                {
                    label: 'Transaction Failed',
                    value: 'Transaction Failed'
                },
                {
                    label: 'Manual Payment',
                    value: 'Manual Payment'
                }
            ],
            userTypeOptions: [
                {
                    label: 'Individual',
                    value: 'Individual'
                },
                {
                    label: 'Organization',
                    value: 'Organization'
                }
            ],
            listUrl: urls.admin.payment.courseRegisterList,
            fields: [
                {
                    name: 'reference_id',
                    sortField: 'reference_id',
                    title: 'Reference ID'
                },
                {
                    name: 'course.name',
                    sortField: 'course.name',
                    title: 'Course'
                },
                {
                    name: 'user',
                    sortField: 'user',
                    title: 'Registered By'
                },
                {
                    name: 'user_type',
                    sortField: 'user_type',
                    title: 'User Type'
                },
                {
                    name: 'total_include_applicants',
                    sortField: 'total_include_applicants',
                    title: 'Total Amount'
                },
                {
                    name: 'payment_status',
                    sortField: 'payment_status',
                    title: 'Payment Status'
                },
                {
                    name: '__slot:is_online_payment',
                    sortField: 'is_online_payment',
                    title: 'Payment Method'
                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewDetails (item) {
            this.$router.push('/admin/course-register/' + item.id + '/details/');
        },
        async updatePaymentStatus (id) {
            this.updatingPayment = true;
            const response = await axios.form(urls.admin.payment.updatingPaymentStatus, {
                id: id,
                status: 'Transaction Completed',
                is_online_payment: false
            });
            const json = response.data;
            if (json.error === false) {
                this.$notify(json.msg, 'Success', {
                    type: 'success'
                });
                const refs = this.$refs;
                refs.table.refreshTable();
            } else {
                if (json.msg) {
                    this.$notify(json.msg, 'Error', {
                        type: 'danger'
                    });
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.updatingPayment = false;
        }
    }
};
</script>

<style scoped>

</style>
